#app {
  height: 100vh;
  display: flex;
  overflow: hidden;
}

#page-wrap,
#page-wrap > div {
  height: 100vh;
  overflow: hidden;
  position: static; /* Re-declare position for Safari */
}

#page-wrap {
  overflow: unset !important;
}

#page-wrap > div {
  position: relative !important;
  overflow: unset !important;
}

html > body .modal.modal-window {
	display:flex !important;
	flex-direction:column;
	height: calc(90vh - 35px);
}

html > body .modal.modal-window .modal-content {
	padding:0px !important;
	overflow-y: hidden;
	max-width:100%;
	display:flex;
	flex-direction:column;
  max-height:100%;
  flex: 1;
}

.modal-action {
	margin-left:auto;
	cursor:pointer;
}
@media only screen and (max-width: 991px)  {
	.modal-action {
		 margin-right:20px;
	}
}

input:focus {
	outline:none
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 13.8px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 1px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 1px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 1px;
  background: #ffffff;
  cursor: pointer;
  height: 8.4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.slick-dots.custom-dots li button:before {
  font-size: 16px;
  color: rgb(192, 192, 192);
  opacity: 1;
}

.slick-dots.custom-dots li.slick-active button:before {
  color: rgb(0, 171, 153);
  opacity: 1;
}

#select-picker {
	-moz-appearance: none; 
	-webkit-appearance: none; 
  appearance: none;
  padding-left: 4px;
  padding-right: 0px;
  background-color: rgb(248, 248, 248);
  border: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM4ZjhlOTUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tZG93biI+PHBvbHlsaW5lIHBvaW50cz0iNiA5IDEyIDE1IDE4IDkiPjwvcG9seWxpbmU+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: right 4px center;
  background-size: 16px;
}

@media only screen and (max-width: 640px)  {
  #main-activity-help-text {
    max-width: 190px !important;
    transform: scale(0.90);
    right: 0px !important;
  }

  #filter-help-text {
    max-width: 190px !important;
    transform: scale(0.90);
    right: 135px !important;
  }

  #filter-help-text-arrow {
    right: 40px !important;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto !important;
}

textarea {
	outline:none !important;
}
.react-pdf__Page__canvas {
  margin: 0 auto !important;
}

@media screen and (min-width: 768px) {
  #searchBarContainer {
    width: 50%;
    height: 34px;
    align-self: center;
  }

  #searchBarContent {
    padding: 0 !important;
    height: unset;
  }
}
